


















































import { defineComponent, onMounted, reactive, ref } from "@vue/composition-api";
import { TokenAPI, TokenList } from "@/types/typeDefinitions";
import usePagination from "@/composables/usePagination";

export default defineComponent({
    name: "ShopifyTokenManagement",
    setup() {
        const { getPaginatedList } = usePagination()

        const fields = [
            { key: 'token', sortable: true },
            { key: 'productId', sortable: true },
            { key: 'customerId', sortable: false },
            { key: 'customerEmail', sortable: false }
            // { key: 'edit', sortable: false }
        ]
        const filter = ref('')
        const currentPage = ref(1);
        const perPage = ref(10);
        const rows = ref(0);
        const pageOptions = [
            { text: '10', value: 10 },
            { text: '25', value: 25 },
            { text: '50', value: 50 },
        ]

        const tokenList = reactive<TokenList[]>([{
            token: '',
            productId: '',
            customerId: undefined,
            customerEmail: ''
        }])

        const convertTokenList = (list: TokenAPI[]) => {
            tokenList.splice(0)
            for (const item of list) {
                let customerId: number | undefined
                let customerEmail = ''

                const { token, productId, subscriptionInfo } = item
                if (subscriptionInfo) {
                    customerId = subscriptionInfo.customerId
                    customerEmail = subscriptionInfo.customerEmail
                }

                tokenList.push({
                    token: token,
                    productId: productId,
                    customerId: customerId,
                    customerEmail: customerEmail
                })
            }
        }

        const updateTokenPagination = async (page?: number) => {
            if (!page) page = currentPage.value
            const offset = (page - 1) * perPage.value
            const {
                list,
                maxCount
            } = await getPaginatedList('api/admin/shopify/tokenList', filter.value, offset, perPage.value)
            convertTokenList(list)
            rows.value = maxCount
        }

        onMounted(() => updateTokenPagination())

        return {
            filter,
            tokenList,
            perPage,
            fields,
            currentPage,
            rows,
            updateTokenPagination,
            pageOptions
        }
    }
})
